// React component for posts form with custom validation

import './App.css';
import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import 'bootstrap/dist/css/bootstrap.min.css';

const API_SUBDOMAIN = "https://workers.ejmaltes.workers.dev/";

class PostEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      parent: props.parent,
      title: "",
      username: "",
      content: "",
      image: "",
      titleRef: React.createRef(),
      usernameRef: React.createRef(),
      contentRef: React.createRef(),
      imageRef: React.createRef(),
      tError: false,
      uError: false,
      cError: false,
      iError: false
    }
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.checkURL = this.checkURL.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.setState({
      show: false,
      validated: false
    });
  }

  handleClose() {
    this.setState({
      show: false
    });
  }

  handleShow() {
    this.setState({
      show: true
    });
  }

  checkURL(url) {
    return(url.match(/\.(jpeg|jpg|gif|png)$/) != null);
  }

  handleSubmit() {
    let title = this.state.title;
    let username = this.state.username;
    let content = this.state.content;
    let image = this.state.image;

    let valid = true;

    if (!title) {
      this.setState({tError: true});
      valid = false;
    } else {
      this.setState({tError: false});
    }

    if (!username) {
      this.setState({uError: true});
      valid = false;
    } else {
      this.setState({uError: false});
    }

    if (!content) {
      this.setState({cError: true});
      valid = false;
    } else {
      this.setState({cError: false});
    }

    if (!this.checkURL(image)) {
      this.setState({iError: true});
      valid = false;
    } else {
      this.setState({iError: false});
    }

    if (valid) {
      let url = API_SUBDOMAIN + "posts";
      let data = {
        title: title,
        username: username,
        content: content,
        image: image
      }
      fetch(url, {
        method: "POST",
        body: JSON.stringify(data)
      })
        .then(resp => resp.text())
        .then(resp => this.state.parent.getCards())
        .catch((err) => {
          console.error(err)
        })
      this.handleClose();
      this.setState({
        title: "",
        username: "",
        content: "",
        image: ""
      });
    }
  }

  render() {
    return (
      <div>
        <Modal show={this.state.show} onHide={this.handleClose} className="wide">
          <Modal.Header closeButton>
            <Modal.Title>Make a Post</Modal.Title>
          </Modal.Header>
          <div className="form-field">
            <label>Title</label>
            <InputGroup className="url">
              <FormControl
                ref={this.titleRef}
                onChange={(event) => {
                  this.setState({ title: event.target.value })
                }}
              />
            </InputGroup>
            <p className={this.state.tError ? "error" : "hidden"}>Please provide a title</p>
          </div>

          <div className="form-field">
            <label>Username</label>
            <InputGroup className="url">
              <FormControl
                ref={this.usernameRef}
                onChange={(event) => {
                  this.setState({ username: event.target.value })
                }}
              />
            </InputGroup>
            <p className={this.state.uError ? "error" : "hidden"}>Please provide a username</p>
          </div>

          <div className="form-field">
            <label>Content</label>
            <InputGroup className="url">
              <FormControl
                ref={this.contentRef}
                onChange={(event) => {
                  this.setState({ content: event.target.value })
                }}
              />
            </InputGroup>
            <p className={this.state.cError ? "error" : "hidden"}>Please provide some content</p>
          </div>

          <div className="form-field">
            <label>Image URL</label>
            <InputGroup className="url">
              <FormControl
                ref={this.imageRef}
                onChange={(event) => {
                  this.setState({ image: event.target.value })
                }}
              />
            </InputGroup>
            <p className={this.state.iError ? "error" : "hidden"}>Please provide a valid image</p>
          </div>

          <Button type="primary" onClick={this.handleSubmit} id="submit-btn">
            Submit
          </Button>
        </Modal>
      </div>
    )
  }
}


export default PostEditor;
