// Posts React App

import './App.css';
import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import PostEditor from './PostEditor';
import 'bootstrap/dist/css/bootstrap.min.css';

const API_SUBDOMAIN = "https://workers.ejmaltes.workers.dev/"

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cards: [],
      editor: React.createRef()
    }
    this.getCards = this.getCards.bind(this);
  }

  componentDidMount() {
    this.getCards();
  }

  getCards() {
    let url = API_SUBDOMAIN + "posts"
    fetch(url)
      .then((resp) => resp.json())
      .then((resp) => {
        this.setState({cards: resp.reverse()});
      })
      .catch((err) => {
        console.error(err)
      })
  }



  render() {

    const renderCard = (card, index) => {
      return (
        <Card>
          <Card.Img variant="top" src={card.image} />
          <Card.Body>
            <Card.Title>{card.title}</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">{card.username}</Card.Subtitle>
            <Card.Text>{card.content}</Card.Text>
          </Card.Body>
        </Card>
      )
    }

    const handleClick = () => {
      this.state.editor.current.handleShow()
    }

    return(
      <div className="App">
        <div className="top-nav">
          <Navbar bg="dark" variant="dark">
            <Container>
              <Navbar.Brand>General Assignment</Navbar.Brand>
              <Navbar.Text>By Ethan Maltes</Navbar.Text>
            </Container>
          </Navbar>
        </div>
        <div id="intro">
          Hello! Welcome to my sample web-app for Cloudflare's general assignment. Enjoy my posts and make some new ones!
        </div>
        <div id="post-btn">
          <Button
            variant="primary"
            onClick={handleClick}
          >
            Post
          </Button>
        </div>
        <div id="card-wrapper">
          <div className="card-area">
            {this.state.cards.map(renderCard)}
          </div>
        </div>
        <PostEditor ref={this.state.editor} parent={this} />
      </div>
    );
  }
}

export default App;
